.mosaic.mosaic-blueprint-theme {
  background: #36383c !important;
}

.mosaic-window-title {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: black !important;
}
