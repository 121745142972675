@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

body {
  margin: 0px;
  height: 100%;
}

.App,
#root,
body,
.mantine-AppShell-body,
.mantine-AppShell-main {
  min-height: 100vh;
}
