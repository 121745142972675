@import "~react-image-gallery/styles/css/image-gallery.css";

.mantine-AppShell-main {
  padding: 0px;
}

html {
  height: 100vh;
  overflow: hidden;
}

.App,
#root,
body,
.mantine-AppShell-body,
.mantine-AppShell-main {
  margin: 0;
  min-height: calc(100vh - 60px);
}

.mantine-AppShell-root {
  height: calc(100vh - 60px);
}

.mantine-AppShell-body {
  margin-top: 60px;
}

.mantine-Tabs-root {
  height: 100%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #fff #36383c;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #36383c;
}

*::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 6px;
  border: 4px solid #36383c;
}
